import React from "react"
import Helmet from "react-helmet"
import { Link } from "gatsby"
import "../assets/styles/styles.scss"
import logo from "../assets/images/andyet-logo.svg"
import favicon from "../assets/images/favicon-wave.png"
import faviconSVG from "../assets/images/favicon-wave.svg"
import useClient from "../util/useClient"
import SubscribeForm from "../components/SubscribeForm"

const Layout = ({ children, page }) => {
  const isClient = useClient()

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/okl1cru.css" />
        <link rel="icon" type="image/svg+xml" href={faviconSVG} />
        <link rel="alternate icon" href={favicon} />
        <body className={`${page} ${isClient ? "js" : "no-js"}`} />
      </Helmet>

      <header className="page-container">
        <nav className="nav-main">
          <Link
            to="/"
            className="nav-home"
          >Meet Our Weirdos
          </Link>

          <a
            href="https://andyet.com"
            aria-label="AndYet"
            title="andyet.com"
            className="nav-andyet"
          >
            <svg
              height="30"
              viewBox="0 0 25.45 30"
              width="25.45"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="ampersand-icon"
                d="m17.54 19.37a.28.28 0 0 0 .39.05c.38-.43 1.18-1.32 1.55-1.75s1.42-1.59 2-2.27a.27.27 0 0 1 .39 0 .27.27 0 0 1 .07.08l1.67 3a.55.55 0 0 1 0 .6c-.32.43-.71.92-1.17 1.46s-1.15 1.31-1.87 2.1a.3.3 0 0 0 0 .37l4.84 6a.29.29 0 0 1 0 .4.28.28 0 0 1 -.18.06h-4.64a.56.56 0 0 1 -.44-.21l-2.53-3.1a.27.27 0 0 0 -.39 0q-3.9 3.84-8.07 3.84a9.29 9.29 0 0 1 -6.53-2.46 8.05 8.05 0 0 1 -2.63-6.11c0-2.89 1.39-5.3 4.17-7.21 4.16-2.87 1.44-1 2-1.42a.26.26 0 0 0 .07-.36 8.78 8.78 0 0 1 -2.66-5.84 6.33 6.33 0 0 1 1.91-4.76 7 7 0 0 1 5-1.84 7 7 0 0 1 4.94 1.78 5.85 5.85 0 0 1 1.95 4.5 5.81 5.81 0 0 1 -1.07 3.4 17.66 17.66 0 0 1 -3.57 3.46.29.29 0 0 0 -.06.4zm-8.31-3.37a.27.27 0 0 0 -.38-.05 41.39 41.39 0 0 0 -3.69 3 3.63 3.63 0 0 0 -1 2.38 4.69 4.69 0 0 0 1.45 3.31 4.67 4.67 0 0 0 3.39 1.42q2.46 0 5.64-3.13a.29.29 0 0 0 0-.39zm2.64-7.22a6 6 0 0 0 .87-.87 2.64 2.64 0 0 0 .55-1.71 2.39 2.39 0 0 0 -.77-1.86 2.93 2.93 0 0 0 -2.06-.71 2.85 2.85 0 0 0 -2 .74 2.27 2.27 0 0 0 -.81 1.78 4 4 0 0 0 1 2.52l1.16 1.33a.29.29 0 0 0 .4 0z"
                fill="#ea0091"
              />
            </svg>
          </a>
        </nav>
      </header>
      <main className="page-container">{children}</main>
      <footer className="site-footer">
        <span className="footer-byline">
          a project by
          <a
            href="https://andyet.com"
            aria-label="AndYet"
            title="andyet.com"
            className="footer-andyet"
          >
            <img src={logo} alt="AndYet logo" />
          </a>
        </span>
        <nav className="utility-links">
          <Link
            to="/privacy"
            className="privacy-link"
          >
            Privacy Policy
          </Link>
          <a
            href="/rss.xml"
            className="rss-link"
          >
            RSS
          </a>
        </nav>
        <a 
          className="portal"
          href="https://find.yourweirdos.com"
          target="_blank"
          title="Find your weirdos this way"
        >Find your weirdos this way</a>
      </footer>
    </>
  )
}

export default Layout
